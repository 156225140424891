import { Route, Routes } from "react-router"
import { CreateTemplateQuote } from "../components/CreateTemplateQuote"
import { Invoice } from "../components/Item/Invoice"
import { AddItem } from "../components/Item/Add"
import { EditQuoteItemForm } from "../components/Item/Edit"
import { Text } from "@chakra-ui/react"
import { AddQuote } from "../components/Quote/Add"
import { InvoiceForm } from "../components/BulkOperations/Invoice"
import { ReceivedForm } from "../components/BulkOperations/Received"
import { OrderForm } from "../components/BulkOperations/Order"
import { Container as QuoteListContainer } from '../components/Quote/List/Container'
import { Container as QuoteItemListContainer } from '../components/Item/List/Container'
import { NoSelectedQuote } from "features/Navigation/NoSelectedQuote"
import { BillingDetailsForm } from "../components/Item/Ordered/Billing"
import { OrderDetailsForm } from "../components/Item/Ordered/Details"
import { AutotaskTicketSetup } from "../components/Item/Ordered/Ticket"
import { AutotaskCost } from "../components/Item/Ordered/Cost"
import { Ordered } from "../components/Item/Ordered"

export const Left = () => (
  <Routes>
    <Route
      path='add/template'
      element={<CreateTemplateQuote />}
    />

    <Route path=":quoteId/order/:itemId/*" element={<Ordered />} />
    {/* <Route
      exact
      path=':quoteId/edit/:itemId/order/billing'
      element={<BillingDetailsForm />}
    />

    <Route
      exact
      path=':quoteId/edit/:itemId/order/details'
      element={<OrderDetailsForm />}
    />

    <Route
      exact
      path=':quoteId/edit/:itemId/order/ticket'
      element={<AutotaskTicketSetup />}
    />

    <Route
      exact
      path=':quoteId/edit/:itemId/order/costs'
      element={<AutotaskCost />}
    /> */}


    <Route
      exact
      path=':quoteId/edit/:itemId/invoice'
      element={<Invoice />}
    />
    <Route path=':quoteId/add' element={<AddItem />} />
    <Route
      path=':quoteId/edit/:itemId'
      errorElement={<Text>Failed to get item</Text>}
      element={<EditQuoteItemForm />}
    />
    <Route
      path='add'
      element={<AddQuote />}
    />
    <Route
      path=':quoteId/invoice'
      element={<InvoiceForm />}
    />
    <Route
      path=':quoteId/received'
      element={<ReceivedForm />}
    />
    <Route path=':quoteId/order' element={<OrderForm />} />
    <Route
      path=':quoteId?'
      element={<QuoteListContainer />} />
  </Routes>
)
export const Right = () => (
  <Routes>
    {
      [
        '/',
        'add',
        'invoice',
        'template'
      ].map((path, i) => (
        <Route
          exact
          key={i}
          path={path}
          element={<NoSelectedQuote />}
        />
      ))
    }

    <Route
      path=':quoteId'
      element={<QuoteItemListContainer />}
    />

    {
      [
        ':quoteId/edit/:itemId',
        'invoice/:quoteId',
        ':quoteId/add',
        ':quoteId/order',
        // ':quoteId/edit/:itemId/order/*',
        ':quoteId/order/:itemId/*',
        ':quoteId/edit/:itemId/invoice'
      ].map((path, i) => (
        <Route
          key={i}
          path={path}
          element={<QuoteItemListContainer />}
        />
      ))
    }
  </Routes>
)