

import { axiosClient } from "lib/axiosClient"
import { useQuery } from "react-query"

export const getQuoteTicketNumbers = async (context) => {
  const { queryKey } = context
  const [_, quoteId] = queryKey
  const { data } = await axiosClient({
    url: `/quotes/ticket-numbers/${quoteId}`,
    method: 'GET'
  })
  return data 
}

export const useGetQuoteTicketNumbers = ({ quoteId, onSuccess, enabled }) => {
  return useQuery(['item-quote-ticket-numbers', quoteId], {
    queryFn: getQuoteTicketNumbers,
    onSuccess,
    enabled
 })
}


