import { useState, useCallback } from "react";
import { useParams, useLocation, useNavigate } from "react-router";
import { useFormContext, useWatch } from "react-hook-form";
import { FormControl, FormErrorMessage, Box, Flex, Text, Button, ButtonGroup, Tooltip } from "@chakra-ui/react";
import { InfoIcon } from '@chakra-ui/icons';

import { useUpdateExistingTicket } from "../api/updateExistingTicket";
import { useGetTicketContact } from "../api/getTicketContact";
import { useGetAutotaskClientContacts } from "features/Quotes/api/getAutotaskClientContacts";
import { useUpdateTicketClient } from "../api/updateTicketClient";

import { Loading } from "components/Loading";
import { AutotaskTicketContactUpdate } from "./AutotaskClientContactUpdate";

import { isValidAutotaskNumber, getNavigationLinks } from "../utils";

export const AutotaskTicketUpdate = () => {
    const { itemId, quoteId } = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const orderForm = useFormContext();
    const autotaskNumber = useWatch({ name: 'autotask_number' });
    const clientId = useWatch({ name: 'client_id' });
    const contactId = useWatch({ name: 'at_client_contact_id' });
    const [invalidTicketClient, setInvalidTicketClient] = useState(false);

    const updateExistingTicket = useUpdateExistingTicket();
    const atClientContacts = useGetAutotaskClientContacts({
        clientId: clientId?.value,
        enabled: !!autotaskNumber && isValidAutotaskNumber(autotaskNumber),
    });

    const atTicketContact = useGetTicketContact({
        ticketNumber: autotaskNumber,
        enabled: !!autotaskNumber && isValidAutotaskNumber(autotaskNumber),
        onSuccess: data => {
            if (data.companyId !== clientId?.value) {
                setInvalidTicketClient(true);
            }
            orderForm.setValue('at_client_contact_id', data.contact);
        },
    });

    const updateTicketClient = useUpdateTicketClient();

    const handleUpdateTicket = async () => {
        if (!(await orderForm.trigger())) return;
        await updateExistingTicket.mutateAsync({
            itemId,
            autotaskNumber,
            contactId: contactId?.value,
        });
        const nextStep = getNavigationLinks(location.pathname).nextStep;
        navigate(`/quotes/${quoteId}/order/${itemId}/${nextStep}`); 
    };

    const handleTicketClientChange = useCallback(async () => {
        await updateTicketClient.mutateAsync({
            itemId,
            autotaskNumber,
            clientId,
            quoteId,
        });
    }, [itemId, autotaskNumber, clientId, quoteId, updateTicketClient]);

    if (!autotaskNumber || !isValidAutotaskNumber(autotaskNumber)) {
        return null;
    }

    if (invalidTicketClient) {
        return (
            <FormControl isInvalid={true}>
                <FormErrorMessage>
                    This ticket is linked to a different client. You need to change the client to match the Quoted client by clicking the button below before you can use this Ticket.
                </FormErrorMessage>
            <ButtonGroup mt="1rem" alignItems="center">
                <Button colorScheme="resolveGreener" variant="outline" isLoading={updateTicketClient.isLoading} onClick={handleTicketClientChange}>
                    Change Client
                </Button>
                <Tooltip
                    colorScheme="resolveOranger"
                    label="Adds the order details to an existing ticket as a ticket note"
                >
                    <InfoIcon />
                </Tooltip>
            </ButtonGroup>
            </FormControl>
        );
    }

    if (
        atTicketContact.isLoading ||
        atTicketContact.isFetching ||
        atClientContacts.isLoading ||
        atClientContacts.isFetching
    )
        return <Loading />;

    if (atTicketContact.isError)
        return (
            <Flex mt="1rem" alignContent="center" direction="column">
                <Box>
                    <Text color="resolveRed">Ticket Not Found</Text>
                </Box>
                {/* {error && <Text fontSize="xs">{error}</Text>} */}
            </Flex>
        );

    return (
        <Box>
            <AutotaskTicketContactUpdate atClientContacts={atClientContacts} />
            <ButtonGroup mt="1rem" alignItems="center">
                <Button
                    mr="0.5em"
                    variant="outline"
                    colorScheme="resolveBluer"
                    isLoading={updateExistingTicket.isLoading}
                    onClick={handleUpdateTicket}
                >
                    Update Ticket
                </Button>
                <Tooltip
                    colorScheme="resolveOranger"
                    label="Adds the order details to an existing ticket as a ticket note"
                >
                    <InfoIcon />
                </Tooltip>
            </ButtonGroup>
        </Box>
    );
};