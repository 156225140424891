import { Internal } from "features/Internal";
import { Quotes } from "features/Quotes";
import { Print } from "features/Quotes/components/Print";
import { Reports } from "features/Reports";
import { Search } from "features/Search";
import { Settings } from "features/Settings";
// import Subs from "features/Subs";
import { Templates } from "features/Templates";
import { Navigate, Route, Routes } from "react-router";
import { lazy } from "react";

const Subs = lazy(() => import('features/Subs'))

export const AppRoutes = () => (
  <>
      <Routes>
        <Route path='quotes/print/:quoteId/:printType' element={<Print />} />
        <Route path='quotes/*' element={<Quotes />} />
        <Route index element={<Navigate to='/quotes' replace />} />
        <Route path='internal/*' element={<Internal />} />
        <Route path='templates/*' element={<Templates />} />
        <Route path='reports/*' element={<Reports />} />
        <Route path='search/*' element={<Search />} />
        <Route path='subs/*' element={<Subs />} />
        <Route path='settings/*' element={<Settings />} />
      </Routes>
  </>
)