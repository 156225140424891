import { useResolveToast } from "hooks/useResolveToast"
import { axiosClient } from "lib/axiosClient"
import { useMutation, useQueryClient } from "react-query"

export const clearTicket = async ({itemId }) => {
    await axiosClient({
        url: `/quotes/item/ticket/${itemId}`,
        method: 'delete'
    })
}

export const useClearTicket = () => {
  const queryClient = useQueryClient()
  const { toastSuccess, toastFail } = useResolveToast()
  return useMutation(clearTicket, {
    onSuccess: () => {
      toastSuccess('Ticket cleared successfully')
      queryClient.invalidateQueries(['item-billing-details'])
      queryClient.invalidateQueries(['quote-items'])
    },
    onError: () => {
      toastFail('Failed to clear Ticket, you must delete costs before clearing')
    }
  })
}
