import { useParams } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';
import {
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    FormControl,
    FormLabel,
    Select,
    useDisclosure
} from '@chakra-ui/react';
import { useGetQuoteTicketNumbers } from '../api/getQuoteTicketNumbers';
import {
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
} from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';

export const ExistingTicketMenu = () => {
    const { quoteId } = useParams();
    const orderForm = useFormContext();

    const { data: ticketNumbers, refetch: getQuoteTicketNumbers } = useGetQuoteTicketNumbers({
        quoteId,
        enabled: true
    });

    return (
        <Menu>
            <MenuButton 
                as={Button} 
                size="sm"
                rightIcon={<ChevronDownIcon />}
                variant="outline" 
                isDisabled={!ticketNumbers?.length || orderForm.getValues('autotask_number')}
                isLoading={getQuoteTicketNumbers.isLoading || getQuoteTicketNumbers.isFetching} 
                colorScheme="resolveOranger"
                onClick={() => getQuoteTicketNumbers()}
            >
                Use Quote Ticket
            </MenuButton>

            <MenuList>
                {ticketNumbers?.map((ticket, i) => (
                    <MenuItem
                        key={i}
                        onClick={() => {
                            orderForm.setValue('autotask_number', ticket, { shouldDirty: true });
                        }}
                    >
                        {ticket}
                    </MenuItem>
                ))}
            </MenuList>
        </Menu>
    );
}