export const STEPS = [
    { label: 'Billing Details', path: 'billing' },
    { label: 'Order Details', path: 'details' },
    { label: 'Autotask Ticket', path: 'ticket' },
    { label: 'Autotask Cost', path: 'costs' }
];

export const ORDER_FORM_DEFAULT_VALUES = {
    ticket_description: null,
    new_user: false,
    new_user_details: null,
    device_build_tier: null,
    services: null,
    notes: null,
    follow_up: false,
    estimated_hours: null,
    client_pon: '',
    payment_method_id: '',
    at_billing_code_id: '',
    client_id: { value: null, label: '' },
    contact_id: '',
    supplier_order_number: '',
    item_ref: '',
    at_cost_id: '',
    autotask_number: '',
    date_ordered: '',
};
