import { useFormContext } from 'react-hook-form';
import { useContext, useEffect } from 'react';
import {
    FormControl,
    FormLabel,
    FormErrorMessage,
    Flex,
    Input,
    Button, Heading
} from '@chakra-ui/react';
import { useUpdateItemTicket } from '../api/updateItemTicket';
import { useNavigate, useParams, useLocation } from 'react-router';
import { getNavigationLinks } from '../utils';
import { useClearTicket } from '../api/clearTicket';
import { AutotaskTicketUpdate } from './AutotaskTicketUpdate';
import { ExistingTicketMenu } from './ExistingTicketMenu';
import { OrderContext } from '..';

export const ExistingAutotaskTicket = () => {

    const { setStepSave } = useContext(OrderContext);
    const { quoteId, itemId } = useParams();
    const navigate = useNavigate();
    const location = useLocation();

    const orderForm = useFormContext();

    const updateItemTicket = useUpdateItemTicket();
    const clearTicket = useClearTicket();

    const handleSave = async () => {
        if (!orderForm.trigger()) {
            return;
        }

        const form = orderForm.getValues();
        await updateItemTicket.mutateAsync({
            itemId,
            form,
        });

        orderForm.reset({}, { keepValues: true });
        const nextStep = getNavigationLinks(location.pathname).nextStep;
        navigate(`/quotes/${quoteId}/order/${itemId}/${nextStep}`);
    };

    const handleAutotaskNumberChange = e => {
        const value = e.target.value;
        orderForm.setValue('autotask_number', value, { shouldDirty: true });
        if (value) {
            orderForm.trigger('autotask_number');
        }
    };

    const handleClearTicket = async () => {
        await clearTicket.mutateAsync({ itemId });
    };

    useEffect(() => {
        setStepSave(() => handleSave);
        return () => setStepSave(null);
    }, [setStepSave, handleSave]);

    return (
        <form onSubmit={orderForm.handleSubmit(handleSave)}>
            <Flex alignItems="center">
                <Heading mr="1rem" size="md" color="resolveOrange">
                    Existing Ticket
                </Heading>
                <Button onClick={handleClearTicket} size="sm" mr="1rem" isLoading={clearTicket.isLoading} variant="outline">
                    Clear Ticket
                </Button>
                <ExistingTicketMenu />
            </Flex>
            <FormControl
                mt="1rem"
                isInvalid={!!orderForm.formState.errors.autotask_number}
            >
                <FormLabel>Autotask Ticket Number</FormLabel>
                <Input
                    {...orderForm.register('autotask_number', {
                        required: true,
                        pattern: {
                            value: /^(T)[0-9]{8}.[0-9]{4}/g,
                            message: 'Enter a valid Autotask ticket number',
                        },
                    })}
                    onChange={handleAutotaskNumberChange}
                    type="text"
                />
                <FormErrorMessage>
                    {orderForm.formState.errors.autotask_number &&
                        'Enter a valid Autotask ticket number'}
                </FormErrorMessage>
            </FormControl>

            <AutotaskTicketUpdate />
        </form>
    );
};
